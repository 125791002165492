var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('div',{staticClass:"register__title"},[_vm._v("Регистрация")]),_c('form',{staticClass:"register__form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Имя","error-text":(_vm.$v.registerModel.name.$dirty && !_vm.$v.registerModel.name.required
            ? 'Поле не заполнено'
            : null) ||
          (_vm.$v.registerModel.name.$dirty && !_vm.$v.registerModel.name.minLength
            ? ("Минимальное количество символов " + (_vm.$v.registerModel.name.$params.minLength.min))
            : null),"has-error":_vm.$v.registerModel.name.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.registerModel.name.$touch()}},model:{value:(_vm.registerModel.name),callback:function ($$v) {_vm.$set(_vm.registerModel, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registerModel.name"}})],1),_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Email","error-text":(_vm.errors && _vm.errors.email ? _vm.errors.email[0] : null) ||
          (_vm.$v.registerModel.email.$dirty && !_vm.$v.registerModel.email.required
            ? 'Поле не заполнено'
            : null) ||
          (_vm.$v.registerModel.email.$dirty && _vm.$v.registerModel.email
            ? "Введите корректный e-mail"
            : null),"has-error":(_vm.errors && !!_vm.errors.email) || _vm.$v.registerModel.email.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.registerModel.email.$touch()}},model:{value:(_vm.registerModel.email),callback:function ($$v) {_vm.$set(_vm.registerModel, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registerModel.email"}})],1),_c('FormGroup',[_c('Field',{directives:[{name:"mask",rawName:"v-mask",value:('+7(###)###-##-##'),expression:"'+7(###)###-##-##'"}],attrs:{"placeholder":"Телефон","error-text":(_vm.errors && _vm.errors.phone ? _vm.errors.phone[0] : null) ||
          (_vm.$v.registerModel.phone.$dirty && !_vm.$v.registerModel.phone.required
            ? 'Поле не заполнено'
            : null),"has-error":(_vm.errors && !!_vm.errors.phone) || _vm.$v.registerModel.phone.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.registerModel.phone.$touch()}},model:{value:(_vm.registerModel.phone),callback:function ($$v) {_vm.$set(_vm.registerModel, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registerModel.phone"}})],1),_c('FormGroup',{staticClass:"register__group"},[_c('BaseCheckbox',{attrs:{"error-text":_vm.$v.terms.$dirty && !_vm.$v.terms.sameAs ? 'Обязательное поле' : null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Я соглашаюсь с "),_c('router-link',{attrs:{"to":"/"}},[_vm._v("Политикой конфиденциальности")])]},proxy:true}]),model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}})],1),_c('div',[_c('BaseButton',{staticClass:"register__submit register__btn",attrs:{"type":"submit"}},[_vm._v(" Зарегистрироваться ")])],1),_c('BaseButton',{staticClass:"register__btn",attrs:{"color":"gray"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'Login' })}}},[_vm._v(" Уже зарегистрированы? ")])],1),_c('Modal',{attrs:{"type":"success","showModal":_vm.showModal,"bgInClass":"animate__fadeIn","bgOutClass":"animate__fadeOut"},on:{"close":function($event){_vm.showModal = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Модальное окно")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v("Регистрация прошла успешно")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.successMessage)+" ")]},proxy:true},{key:"buttons",fn:function(){return [_c('BaseButton',{attrs:{"large":true},on:{"click":function($event){return _vm.$router.push({ name: 'Login' })}}},[_vm._v("Войти")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }